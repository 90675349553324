import React, { useState } from 'react';
import { API } from "../../config/api.common";

async function reqResetPassword(email) {
  return fetch(API.AUTH.REQ_RESET_PASS, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  }).then((data) => data.statusText);
}

async function resetPassword(data) {
  return fetch(API.AUTH.RESET_PASS, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((data) => data.statusText);
}

const AccountRecovery = (props) => {
  const resetToken = props.match.params.passwordToken;
  
  const [reqSent, setReqSent] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [securityLVL, setSecurityLVL] = useState();  

  const checkSecurityLVL = async e=> {
    if(password!==undefined) {
      if (password.length <8 ) {
        setSecurityLVL("La contraseña debe tener más de 8 caracteres")
      } else 
      if (password.search(/[a-z]/) < 1 || password.search(/[0-9]/) < 0) {
        setSecurityLVL("La contraseña debe tener letras y números")
      } else {
        setSecurityLVL("Nivel de seguridad alto")
      }
    }    
  }

  const handleSubmit = async e => {
    e.preventDefault();    
    var id = e.target.id;
    if (id==="reqReset") {
      var requestSent = await reqResetPassword({
        email: email
      });
      if (requestSent === 'OK'){
        setReqSent(true)
      } else {

      }
    } else if (id==="resetPass"){
      var passReset = await resetPassword({
        resetPasswordToken: resetToken,
        password: password
      })
    }       
  }

  return(
    <div className="d-flex justify-content-center align-items-center container-comp">
        {
          !resetToken && !reqSent? (         
            <div className="form-container col-md-5 d-flex justify-content-center align-items-center flex-column">
              <span className="title-form">Recuperar contraseña</span>                 
              <div className='col-md-8'>
                  <p style={{textAlign: 'justify'}}>Ingrese el correo electrónico registrado y se le enviará un link por correo para reestablecer su contraseña.</p>
                </div> 
              <form className='col-md-8' id="reqReset" onSubmit={handleSubmit}>                             
                <label>
                  <span>Correo electrónico</span>
                  <input name="email" type="email" onChange={e => {setEmail(e.target.value)}} />
                </label>                                
                <div>
                  <button type="submit">Solicitar cambio de contraseña</button>
                </div>
              </form>  
            </div>
          ): (
            <></>
          )
        }
        {
          resetToken? (
            <div className="form-container col-md-5 d-flex justify-content-center align-items-center flex-column">
              <span className="title-form">Cambiar contraseña</span>   
              <span className="info col-md-9">La contraseña debe poseer entre 8 o más caracteres y ser una combinación de números y letras, sin acento y sin la letra “Ñ o ñ”.</span> 
              <br></br>
              <form className='col-md-9' id="resetPass" onSubmit={handleSubmit}>
                <label>
                  <span>Nueva contraseña</span>
                  <input name="password" type="password" onChange={e => {setPassword(e.target.value); checkSecurityLVL(e)}} />
                </label>
                <div className="password-utils d-flex justify-content-start">
                  <span>{securityLVL}</span>
                </div>                 
                <div>
                  <button type="submit">Cambiar Contraseña</button>
                </div>
            </form>  
          </div>  
        ): (
          <></>
        )          
      }   
      {
        !resetToken && reqSent? (
          <div className="form-container col-md-5 d-flex justify-content-center align-items-center flex-column">
            <span className="title-form">Correo de recuperación enviado</span>    
            <div style={{padding: '2rem'}}><p style={{textAlign: 'center'}}>Se ha enviado un correo con el link para reestablecer su contraseña. Por favor revise la dirección de correo registrada.</p>
            </div>                                        
          </div>
        ) :(
          <></>
        )
      }                        
    </div>
  )
}

export default AccountRecovery;