switch (process.env.ENVIROMENT) {
  case "local":
    break;

  case "prod":
    break;

  default:
    break;
}

export const CONFIG = {
  APP_PORT: process.env.PORT,
  API_URL: process.env.API_URL ?? 'https://uv-api.talentum.edu.co/',
};
