import React, { useEffect, useState } from "react";
import { API } from "../../config/api.common";

import '../templates.css';

const ActivateUser = (props) => {  
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const id = props.match.params.id;
  const code = props.match.params.code;

  useEffect(() => {
    fetch(API.AUTH.ACTIVATE_ACCOUNT+'?uid='+id+'&code='+code)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);          
        },
        // Nota: es importante manejar errores aquí y no en
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className='container-comp d-flex justify-content-center align-items-center'>
        <div className="form-container col-md-5 d-flex align-items-center justify-content-center flex-column">
          <h2>Tu cuenta está activa</h2>        
          <h4>¡Gracias por unirte a Universum!</h4>
          <br></br>
          <a className="btn" href="/login"><button>Ir a Inicio de Sesión</button></a>
        </div>    
      </div>    
    )
  }
}

export default ActivateUser;
