
//import useState hook to create menu collapse state
import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "react-bootstrap";

import { FaRegBell } from "react-icons/fa";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";

const Header = (props) => {  
  const nombre = localStorage.getItem('nombre');
  const correo = localStorage.getItem('correo');
  const foto = localStorage.getItem('foto');

  useEffect(() => {

  }, []);   

  return (
    <div>
    <Navbar color="danger" light expand="md">
      <NavbarBrand>{props.pageName}</NavbarBrand>
        <Nav className="mr-auto" navbar>
          <NavItem className="d-flex align-items-center">
            <NavLink className="notif">
              <div className="dot"></div>
              <FaRegBell size={30}/>
            </NavLink>
          </NavItem>
          <NavItem className="d-flex flex-row">
            <div className="profile-data d-flex flex-column">
              <span>{nombre}</span>
              <span style={{fontSize:"12px"}}>{correo}</span>
            </div>
            <div className="profile-picture d-flex justify-content-center align-items-center">
              AD
            </div>
          </NavItem>
        </Nav>       
      </Navbar>
    </div>                      
  )
};

export default withRouter(Header);