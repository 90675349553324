import React, { useState, useEffect } from "react";

import { Chart } from "react-google-charts";
import { API } from '../../config/api.common';

import './Dashboard.css';

function Dashboard() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [errorActive, setErrorActive] = useState(null);
  const [isLoadedActive, setIsLoadedActive] = useState(false);
  const [usersActive, setUsersActive] = useState([]);
  
  var token = localStorage.getItem("token");  
  const bearer  = 'Bearer ' + token;
  
useEffect(() => {
  console.log(bearer)

  fetch(API.USERS.ALL, {
    headers: {
      "Authorization": bearer,
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setUsers(result);
      },
      // Nota: es importante manejar errores aquí y no en
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
    fetch(API.USERS.ACTIVE, {
      headers: {
        "Authorization": bearer,
      },
    })
    .then((res) => res.json())
    .then(
      (result) => {
        setIsLoadedActive(true);
        setUsersActive(result);
      },
      // Nota: es importante manejar errores aquí y no en
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      (error) => {
        setIsLoadedActive(true);
        setErrorActive(error);
      }
    );
}, []);

  const options = {
    title: "Usuarios Activos vs. Franja horaria",
    hAxis: { title: "Franja Horaria", viewWindow: { min: 0, max: 5 } },
    vAxis: { title: "Usuarios Activos", viewWindow: { min: 0, max: 5 } },
    legend: "none"
  };

  var data = [
    ['Franja Horaria', 'Número de Usuarios', 'Usuarios Activos'],
    ['10AM-12M', users.length, usersActive.length],
    ['12M-2PM',  usersActive.length, users. length]
  ];
  
  if (error || errorActive) {
    return <div>Error: {error.message} || {errorActive.message}</div>;
  } else if (!isLoaded && !isLoadedActive) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="comp-container d-flex justify-content-between align-items-center flex-column">
        <Chart
        chartType="Bar"
        data={data}
        options={options}
        width="80%"
        height="400px"
        legendToggle
        />
      </div>
    );
  }
}

export default Dashboard;
