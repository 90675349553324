import React from "react";

import '../templates.css';

const CloseThis = () => {  
  
    return (
      <div className='container-comp d-flex justify-content-center align-items-center'>
        <div className="form-container col-md-5 d-flex align-items-center justify-content-center flex-column">
          <h2>¡Estás autenticado!</h2>        
          <h4>Puedes cerrar esta ventana y dirigirte a Universum.</h4>          
        </div>    
      </div>    
    )  
}

export default CloseThis;
