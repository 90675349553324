
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import Login from "../Utils/Login/Login";
import Register from "../Utils/Register/Register";
import ActivateUser from "../Templates/ActivateUser/activateUser";
import CloseThis from "../Templates/closeThis/closeThis";
import AccountRecovery from "../Utils/AccountRecovery/AccountRecovery";

const RoutesUnprotected = () =>{    
    return (
      <BrowserRouter basename="app">
        <Switch>         
          <Route exact path="/"><Redirect to="/login" /> : <Login /></Route>
          <Route exact path="/login/:origin?" component={Login}/>
          <Route exact path="/register" component={Register} />
          <Route exact path="/activate-user/:id?/:code?" render={(props) => <ActivateUser {...props} />}/>
          <Route exact path="/origin-universum/authenticated" component={CloseThis}/>        
          <Route exact path="/account-recovery/:passwordToken?" component={AccountRecovery}/>  
        </Switch>
      </BrowserRouter>
    );
};

export default RoutesUnprotected;
