import { CONFIG } from "./config.common";

const ENDPOINT = {
  _auth: "auth",
  _users: "users",
  _zones: "zones",
  _zone_state: "zone_state",
  _pictures: "picture",
};

export const API = {
  AUTH: {
    BASE: ``,
    LOGIN: `${CONFIG.API_URL}/${ENDPOINT._auth}/login`,
    REGISTER: `${CONFIG.API_URL}/${ENDPOINT._auth}/register`,
    REQ_RESET_PASS: `${CONFIG.API_URL}/${ENDPOINT._auth}/req/reset-password`,
    RESET_PASS: `${CONFIG.API_URL}/${ENDPOINT._auth}/reset-password`,
    ACTIVATE_ACCOUNT: `${CONFIG.API_URL}/${ENDPOINT._auth}/activate-account`,

  },
  USERS: {
    BASE: `${CONFIG.API_URL}/${ENDPOINT._users}/`,
    ALL: `${CONFIG.API_URL}/${ENDPOINT._users}/all`,
    ACTIVE: `${CONFIG.API_URL}/${ENDPOINT._users}/active/all`,
  },
  PICTURE: {
    REGISTER: `${CONFIG.API_URL}/${ENDPOINT._pictures}/to-register`,
    LOGIN: `${CONFIG.API_URL}/${ENDPOINT._pictures}/to-login/`,
  },
  ZONE: {
    ALL: `${CONFIG.API_URL}/${ENDPOINT._zones}/all`,
  },
  ZONE_STATE: `${CONFIG.API_URL}/${ENDPOINT._zone_state}/save-bin`,
};
