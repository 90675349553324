import React from 'react';
import { Route, Redirect, Switch, BrowserRouter} from 'react-router-dom';

import Header from '../Templates/Header/Header';
import Sidebar from '../Templates/Sidebar/Sidebar';

import RoutesUnprotected from './RoutesUnprotected';
import Dashboard from '../Components/Dashboard/Dashboard';

function Routes({component: Component, ...restOfProps }) {  
    let pageName = "Dashboard";
 
    const styles = {
        mainContainer: {
            width:'100vw',
            position: 'fixed',
        },     
        contentDiv: {      
            overflowY:'auto',
            width: '100%',
            height: 'auto',
        },  
        contentMargin: {
            height: '92vh',
            overflowY:'auto',  
            paddingBottom: '1rem',
            backgroundColor: '#E5E5E5',

        },
    }; 
 
    const isAuthenticated = localStorage.getItem("token");   
       
    return (        
        <BrowserRouter basename="app">
            <Route
                {...restOfProps}
                render={(props) =>
                    isAuthenticated  ? 
                        <div className="d-flex flex-row align-items-start" style={styles.mainContainer}>
                            <div style={styles.sidebar}>
                                <Sidebar></Sidebar>
                            </div>
                            <div style={styles.contentDiv}>
                                <Header pageName={pageName}  {...props}/>
                                <div>                                
                                    <div>                                    
                                        <Switch>
                                            <Route exact path="/"><Redirect to="/dashboard" /> : <Dashboard /></Route>
                                            <Route exact path="/dashboard" component={Dashboard}/>          
                                        </Switch>                                                                             
                                    </div>
                                </div>
                            </div>   
                        </div>                                                     
                    :
                    <RoutesUnprotected></RoutesUnprotected>
                }            
            />   
        </BrowserRouter>     
    );
};

export default Routes;
