import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import useToken from "../../App/useToken";
import jwt_decode from "jwt-decode";

import Toast from "react-bootstrap/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Login.css";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../config/api.common";

const eye = <FontAwesomeIcon icon={faEye} />;

async function loginUser(credentials) {
  return fetch(API.AUTH.LOGIN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => {
    var obj ={
      data : data.json(),
      status : data.status
    }
    return obj;
  });
}

const Login = (props) => {
  const origin = props.match.params.origin;
  
  var [warning, setWarning] = useState();
  const [show, setShow] = useState(false);

  var { token, setToken } = useToken();
  
  const [decoded, setDecoded] = useState({})
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  
  const [state, setState] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [imgs, setImgs] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const authenticatedUser = await loginUser({
      username: username,
      password: password,
    })

    switch(authenticatedUser.status) {
      case 201:               
        authenticatedUser.data.then((dataInfo)=>{  
          token = dataInfo.accessToken;        
          if (token) {
            var obj = jwt_decode(token);
            setDecoded(obj);
            if (decoded.role !== 'student') {
              setToken(token);
              window.location.assign("/dashboard");
            } else {
              window.location.assign("/origin-universum/authenticated");
            }        
          } else {
            setWarning("Nombre de usuario o contraseña incorrectos");
            setShow(true);
          }
        })          
        break;
      case 422 :
        setWarning("Este usuario no se encuentra activo.");
        setShow(true);
        break;
      case 404 :
        setWarning("El usuario no existe");
        setShow(true);
        break;
      default:
        setWarning("Ha ocurrido un problema");
        setShow(true);
        break;
    }    
  };

  useEffect(() => {
    fetch(API.PICTURE.REGISTER)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setImgs(result);
        },
        // Nota: es importante manejar errores aquí y no en
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const togglePasswordVisiblity = () => {
    setState(state ? false : true);
  };

  return (
    <div className="d-flex justify-content-center align-items-center container-comp">
      <Toast
        bg="warning"
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>{warning}</Toast.Body>
      </Toast>
      <div className="form-container col-md-5 d-flex justify-content-center align-items-center flex-column">
        <span className="title-form">Iniciar Sesión</span>
        {
          !origin? (         
            <form className="col-md-7" onSubmit={handleSubmit}>
              <label>
                <span>Nombre de usuario</span>
                <input
                  name="username"
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </label>
              <label className="pass-wrapper">
                <span>Contraseña</span>
                <input
                  type={state ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i onClick={togglePasswordVisiblity}>{eye}</i>
              </label>
              <div className="password-utils d-flex justify-content-between">
                <div className="col-md-5 d-flex align-items-center">
                  <input
                    className="col-md-2"
                    type="checkbox"
                    id="rememberPass"
                    value="rememberPass"
                  ></input>
                  <label className="no-margin col-md-8" htmlFor="rememberPass">
                    Recuérdame
                  </label>
                </div>
                <a href="/account-recovery">¿Olvidaste tu contraseña?</a>
              </div>
              <div>
                <button type="submit">Iniciar Sesión</button>
              </div>
            </form>
          ) : (
            <form className="col-md-9" onSubmit={handleSubmit}>
              <label>
                <span>Nombre de usuario</span>
                <input
                  name="username"
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </label>
              <label className="pass-wrapper">
                <span>Contraseña:</span>                
              </label>
              {
                error? (
                   <div>Error: {error.message}</div>
                 ) :  !isLoaded? (
                  <div>Loading...</div>
                 ) : (
                  <div className="col-md-12 d-flex justify-content-between">
                    {imgs.map(function (obj, i) {
                      return (
                        <label key={obj.id}>
                          <input
                            type="radio"
                            name="test"
                            value={obj.id}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <img
                            src={obj.url}
                            alt="Option 1"
                            width="100px"
                            height="100px"
                          />
                        </label>
                      );
                    })}
                  </div>
                 )                  
              }
              <div className="password-utils d-flex justify-content-center">                
                <a href="/account-recovery">¿Olvidaste tu contraseña?</a>
              </div>
              <div>
                <button type="submit">Iniciar Sesión</button>
              </div>
            </form>
          )          
        }        
        <div style={{ marginTop: `1rem` }}>
          <span>
            ¿Nuevo en Universum? <a href="/register">Crea una cuenta</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
