import React, { useState, useEffect } from 'react';
import { withRouter , Link} from 'react-router-dom';

import Form from 'react-bootstrap/Form';

import './Sidebar.css';

import useCurrentWidth from '../../App/useCurrentWidth';

import {
    Menu,
    MenuItem,
    ProSidebar,
    SidebarHeader,
  } from "react-pro-sidebar";

  import {
    NavbarBrand,    
  } from "react-bootstrap";

//import icons from react icons
import { CgLogOut } from "react-icons/cg";
import { FaChartBar, FaFileContract, FaRedo, FaCog } from "react-icons/fa";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";

let menuItems= [
    {route: '/dashboard', pathname:'Dashboard', icon:<FaChartBar />},    
    {route: '/configuraciones', pathname:'Configuraciones', icon:<FaCog />},
    
];
let places = [];


function Sidebar (props, {defaultActive}){   
    const [place, setPlace] = useState();    
    const placeId = localStorage.getItem('placeId');   

    const setPlaceId = (id) =>{
        localStorage.setItem('placeId', id);
    }

    const handleSubmit = (e) => {
        setPlaceId(e.value);        
    }
                   
    let width = useCurrentWidth();    
 
    var [collapsed, setCollapsed] = useState(false);
    const styles = {       
        sideBarHeight: {
            height: "100vh",
        },       
        menuIcon: {
            float: "right",
            margin: "10px",
        },        
        header: {
            zIndex:'999'
        }
    };
    
    const onClickMenuIcon = () => {
        setCollapsed(!collapsed);
    };

    if(width <1300) {
        collapsed =true;
    }    
    
    //Those location props finally come in handy!
    const location = props.history.location;
    //Load this string from localStorage
    const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
    //Parse it to a number
    const lastActiveIndex = Number(lastActiveIndexString);
    //Store it in state
    const [activeIndex, setActiveIndex] = useState(lastActiveIndex || Number(defaultActive));
 
    //This sets the item to localStorage and changes the state index
    function changeActiveIndex(newIndex) {
        localStorage.setItem("lastActiveIndex", newIndex)
        setActiveIndex(newIndex)
    }
 
    //Appends '/' to the start of a string if not present
    function getPath(path) {
        if (path.charAt(0) !== "/") {
            return  "/" + path;
        }
        return path;
    }

     //This re-renders when the route changes
    useEffect(()=> {
        //Get an item with the same 'route' as the one provided by react router (the current route)
        const activeItem = menuItems.findIndex(item=> getPath(item.route) === getPath(location.pathname))
        changeActiveIndex(activeItem);
    }, [location])
   
    //...
    return (
        <ProSidebar style={styles.sideBarHeight} collapsed={collapsed}>
            <SidebarHeader style={styles.header} >
                {collapsed ? <span style={{fontSize: '24px', fontWeight: '600'}}>U</span> : <NavbarBrand>Universum</NavbarBrand>}          
                <div style={styles.menuIcon} onClick={onClickMenuIcon}>
                {collapsed ? (
                    <FiArrowRightCircle size={23}/>
                ) : (
                    <FiArrowLeftCircle  size={23}/>
                )}
                </div>
            </SidebarHeader>
            <Menu iconShape="square">    
            {     
                menuItems.map((item, index)=>{   
                    return (
                        <MenuItem className="menuItem" icon={item.icon} key={item.pathname} active={index === activeIndex}>
                            <Link to={item.route}>{item.pathname}</Link>
                        </MenuItem>                                                    
                    );
                })
            }
                              
            <MenuItem className="logoutIcon" icon={<CgLogOut/>} onClick={ async function LogOut(){    
                    localStorage.removeItem('token');
                    window.location.assign('/login');
                } }>   Cerrar Sesión          
            </MenuItem>           
            </Menu>
        </ProSidebar>                      
    );
}

export default withRouter(Sidebar);

